import { useEffect, useState } from 'react';
import styles from './callingState.module.scss'
import { useDispatch, useSelector } from 'react-redux';
// import * as mqtt from 'mqtt/dist/mqtt.js'
import { useLocation, useNavigate } from 'react-router-dom';
import { Call_Status, Process_Status, cancelCallTypes, mqtt_Message, path, serverFeild } from '../../utils/Constants';
import { toast } from 'react-toastify';
import Modal from '../modal/Modal';
import Spinner from '../spinner/Spinner';
import { FirebasePushNotifReq, LetsCallReq } from '../../services/requests';
import Cookies from 'js-cookie';
import MainLayout from '../layout/mainLayout/MainLayout';
import CancelDialBtn from '../buttons/cancelDial/CancelDialBtn';
import CallStatus from '../callStatus/CallStatus';
import CharacterAvatar from '../characterimage/CharacterAvatar';
import { setMediaDomains, setPartnerAccountId, setPartnerCharacterId, setPartnerName, setTalksId } from '../../redux/slice';

const CallingState = ({ callManager }) => {

  const { partner_account_id, partner_character_id, accessToken } = useSelector(state => state.auth);
  const [isMqttConnected, setIsMqttConnected] = useState(false);
  const [mqttMessage, setMqttMessage] = useState('');
  const [talkId, setTalkId] = useState(null)
  const [mediaDomain, setMediaDomain] = useState('')
  const [loading, setLoading] = useState(false);
  const [callStatus, setCallStatus] = useState(Call_Status.CONNECTING)
  const [isFirstRender, setIsFirstRender] = useState(true)
  const [wsStatus, setWsStatus] = useState('');
  const navigate = useNavigate()
  const location = useLocation()
  const palcode = location.state
  const dispatch = useDispatch()
  const handelingCallStatus = (callresponse) => {
    setMqttMessage(callresponse)
    if (callresponse === mqtt_Message.AVAILABLE) {
      setCallStatus(Call_Status.RINGING)
    } else if (callresponse === mqtt_Message.BUSY) {
      toast.error('your friend is busy ')
      navigate(path.Home)
    } else if (callresponse === mqtt_Message.REJECT) {
      setCallStatus(Call_Status.REJECTED)
      navigate(path.USERINFORM, { state: { palcode: palcode, callCurrentStatus: Call_Status.REJECTED } })
    } else if (callresponse === mqtt_Message.ANSWER) {
      setCallStatus(Call_Status.PREPERING)
      navigate(path.TALKING, { state: { palcode: palcode, callCurrentStatus: Call_Status.PREPERING } })
    }
  }
  // const callStatusSetter = (status) => {
  //   setCallStatus(status)
  // }
  // callmanager.setStatusUpdater(callStatusSetter)
  useEffect(() => {
    let bodyData;
    // Register the service worker
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.ready.then((registration) => {
        // alert('ready')
        registration.active.postMessage('init');

        navigator.serviceWorker.addEventListener('message', (event) => {
          // console.log('service worker', event.data.data?.callResponseType);
          // alert('message')
          if (event.data.data) {
            // alert(event.data.data?.callResponseType)
            // handelingCallStatus(event.data.data?.callResponseType)
          }
        });
      });
    }
  }, []);

  useEffect(() => {
    if (isFirstRender) {
      callManager.CreateCoreWsConnecton(accessToken)
      const handleCallResponseType = (responseType) => {
        // alert(responseType)
        // console.log('calling state web socket ', responseType);
        handelingCallStatus(responseType)
      };
      // callManager.setStatusCallback(setWsStatus);
      callManager.addListenerToCoreWebSocket(handleCallResponseType)
      setIsFirstRender(false);
    }

  }, []);
  //first, we trying to connect to mqtt connection 
  useEffect(() => {
    setLoading(false)
    setCallStatus(Call_Status.CALLING)
    setIsMqttConnected(true)
    // const messaging = getMessaging();
    // const unsubscribe = onMessage(messaging, (payload) => {
    //   console.log('Message received. ', payload);
    //   if (location.pathname === path.CALLING) {
    //     // handelingCallStatus(payload.data.callResponseType)
    //   }
    // });
    // return () => {
    //   unsubscribe();
    // };
  }, [location.pathname]);
  //if mqtt connection was successfull==>sending let's call request
  useEffect(() => {
    if (isMqttConnected) {
      if (palcode.length === 6) {
        // console.log('sending lets call request');
        LetsCallReq('/v1/lets-call', {
          pal_account_id: partner_account_id,
          pal_character_id: partner_character_id,
          language_id: serverFeild.language_id,
          character_id: serverFeild.charecter_id,
          platform: serverFeild.Platform,
          app_version: serverFeild.appVersion,
          // pal_number: `${palcode}`
        }).then((res) => {

          Cookies.set('tId', res.talk_id)
          dispatch(setTalksId(res.talk_id))
          setTalkId(res.talk_id)
          setMediaDomain(res.media_domain)
          dispatch(setMediaDomains(res.media_domain))
          dispatch(setPartnerName(res.pal_name))
        }).catch(() => {
          toast.error('pal number is not valid')
          navigate(path.Home)
        })
      } else {
        // console.log('sending lets call request');
        LetsCallReq('/v1/lets-call', {
          pal_account_id: 0,
          pal_character_id: 0,
          language_id: serverFeild.language_id,
          character_id: serverFeild.charecter_id,
          platform: serverFeild.Platform,
          app_version: serverFeild.appVersion,
          pal_number: `${palcode}`
        }).then((res) => {

          Cookies.set('tId', res.talk_id)
          dispatch(setTalksId(res.talk_id))
          setTalkId(res.talk_id)
          setMediaDomain(res.media_domain)
          dispatch(setMediaDomains(res.media_domain))
          dispatch(setPartnerAccountId(res.pal_account_id))
          dispatch(setPartnerCharacterId(res.pal_character_id))
          dispatch(setPartnerName(res.pal_name))
        }).catch(() => {
          toast.error('pal number is not valid')
          navigate(path.Home)
        })
      }


    }
  }, [isMqttConnected])
  //if mqtt connection was successfull==>sending let's call request
  useEffect(() => {
    let sendingTimes = 0
    const pushReq = () => {
      if (talkId && mediaDomain && mqttMessage !== mqtt_Message.AVAILABLE && mqttMessage !== mqtt_Message.ANSWER) {
        // console.log('sent time', sendingTimes);
        // console.log('sending push notife');
        try {
          if (palcode.length === 6) {
            FirebasePushNotifReq('/v1/firebase', {
              type: "webCall",
              pal_account_id: partner_account_id,
              media_domain: mediaDomain,
              talk_id: talkId,
              pal_code: palcode,
              platform: serverFeild.Platform,
              character_id: serverFeild.charecter_id,
            }).catch(() => {
              console.log('errror in push notif');
            })
            sendingTimes = sendingTimes + 1;
          } else {
            FirebasePushNotifReq('/v1/firebase', {
              type: "palNumberWebCall",
              pal_account_id: partner_account_id,
              media_domain: mediaDomain,
              talk_id: talkId,
              platform: serverFeild.Platform,
              pal_character_id: serverFeild.charecter_id,
              pal_name: 'palphoneWeb',
              send_time: Number(new Date().toISOString().replace(/[T:.Z-]/g, ''))
            }).catch(() => {
              console.log('errror in push notif');
            })
            sendingTimes = sendingTimes + 1;
          }


          if (sendingTimes >= 3) {
            clearInterval(intervalId);
          }
        } catch (error) {
          console.log('error');
        }

      }
    }
    pushReq();
    const intervalId = setInterval(pushReq, 10000); // 10 seconds
    return () => {
      clearInterval(intervalId);
    };
  }, [talkId, mediaDomain, mqttMessage])
  //if mqtt connection was successfull==>sending let's call request
  useEffect(() => {
    // Set a timeout for 30 seconds for checking wheather caller is available 
    const callingTimeout = setTimeout(() => {
      if (isMqttConnected && callStatus !== Call_Status.RINGING && callStatus !== Call_Status.PREPERING) {
        toast.error('your palcode owner is not available', { position: toast.POSITION.TOP_CENTER })
        try {
          FirebasePushNotifReq('/v1/firebase', {
            type: cancelCallTypes.MISSEDCALL,
            pal_account_id: partner_account_id,
            media_domain: mediaDomain,
            talk_id: talkId,
            pal_code: palcode,
            platform: serverFeild.Platform,
            ttlInMinutes: (3 * 1440)
          }).catch(error => {
            console.log('error in firebase', error);
          })
        } catch (error) {
          console.log('error in firebase', error);
        }

        navigate(path.Home)
        // You can handle this case as needed
      }
    }, 30000);
    // Set a timeout for 30 seconds for checking wheather caller rejecting or anwsering or nothing
    const ringingTimeout = setTimeout(() => {
      if (isMqttConnected && callStatus !== Call_Status.PREPERING) {
        FirebasePushNotifReq('/v1/firebase', {
          type: cancelCallTypes.RINGINGMISSEDCALL,
          pal_account_id: partner_account_id,
          media_domain: mediaDomain,
          talk_id: talkId,
          pal_code: palcode,
          platform: serverFeild.Platform,
          ttlInMinutes: (3 * 1440)
        })
        setCallStatus(Call_Status.NORESPONSE)
        navigate(path.USERINFORM, { state: { palcode: palcode, callCurrentStatus: Call_Status.NORESPONSE } })
      }
    }, 60000);
    return () => {
      clearTimeout(callingTimeout);
      clearTimeout(ringingTimeout);
    }
  }, [callStatus, talkId])
  return (
    <>
      {loading && <Modal isOpen={loading}>
        <Spinner />
      </Modal>
      }
      <MainLayout>
        <div className={styles.callingContainer}>
          <CallStatus currentStatus={callStatus} />
          <CharacterAvatar characterId={partner_character_id} title={location.state} />
          {/* <span >{wsStatus === 'close' ? <span style={{ color: 'red', fontSize: '2.5rem' }}>.</span> : <span style={{ color: 'green', fontSize: '2.5rem' }}>.</span>}</span> */}
          {talkId && mediaDomain && <CancelDialBtn palcode={palcode} talkId={talkId} mediaDomain={mediaDomain} callStatus={callStatus} />}
        </div>
      </MainLayout>
    </>
  )
}
export default CallingState