import { useDispatch } from 'react-redux'
import styles from './dialBtn.module.scss'
import { MdCall } from 'react-icons/md'
import { setAccessTokens, setAccountId, setDeviceId, setFirebaseTokens, setPartnerAccountId, setPartnerCharacterId, setRefreshTokens, setSessionId } from '../../../redux/slice'
import { GetAccountId, getRefreshTokenReq, getReq } from '../../../services/requests'
import { Encryption_Keys, cookiesLabel, serverFeild } from '../../../utils/Constants'
import { toast } from 'react-toastify'
import { v4 as uuidv4 } from 'uuid';
import { SaveInCookies, decodeToken, getFromCookies } from '../../../utils/helperFunctions'
import Modal from '../../modal/Modal'
import { useState } from 'react'
import Spinner from '../../spinner/Spinner'
import { getFirebaseToken } from '../../../firebase'
import { MdError } from "react-icons/md";
import Cookies from 'js-cookie'
const DialBtn = ({ disable, palcode, setPalcode, setIsModalOpen }) => {
  const [loading, setLoading] = useState(false)
  const [isPalcodeValid, setIsPalcodeValid] = useState(true)
  const dispatch = useDispatch()
  //mqtt handler use to connect to mqtt broker and conver  mqqt connection object  to string and save it in redux
  // const mqttHandler = (token) => {
  //   const mqtt = mqttConnection(token)
  //   const mqttStringForKeepInRedux = JSON.stringify(mqtt);
  //   dispatch(setMqtt(mqttStringForKeepInRedux))
  // }

  const onClickDialHandler = () => {
    //dial Handler    
    setLoading(true)
    // const getAccessToken = Cookies.get(cookiesLabel.ACCESSTOKEN)
    const getAccessToken = getFromCookies(cookiesLabel.ACCESSTOKEN, Encryption_Keys.ACCESS_TOKEN_key)
    const refreshTokens = Cookies.get(cookiesLabel.REFRESHTOKEN)
    const deviceid = Cookies.get(cookiesLabel.DEVICEID)
    const sessionid = Cookies.get(cookiesLabel.SESSIONID)
    const firebaseToke = Cookies.get(cookiesLabel.FIREBASETOKEN)
    // first, ckeching wheather refresh token is expire , if expire ==> remove all cookies info and redux state info such as account id and tokens , then , if refresh token not expire chkeck wheather access token expire or not , if expire ==> get new tokens and save them in redux and cookies
    if (refreshTokens) {
      const decodedToken = decodeToken(refreshTokens);
      if (decodedToken && decodedToken.exp) {
        const expirationTime = decodedToken.exp * 1000; // Convert to milliseconds
        const remainingTime = expirationTime - Date.now();
        //in core , should be 7400000
        if (remainingTime < 7400000) {
          console.log('refresh expired');
          GetAccountId('/v1/accounts', {
            device_id: deviceid,
            session_id: sessionid,
            platform: serverFeild.Platform,
            app_version: serverFeild.appVersion,
            update_firebase: false,
            firebase_token: firebaseToke
          }).then((res) => {
            console.log(res);
            SaveInCookies(res.tokens.access_token, res.tokens.refresh_token, res.account_id)
            dispatch(setAccessTokens(res.tokens.access_token))
            dispatch(setRefreshTokens(res.tokens.refresh_token))
          }).catch(error => console.log('error in account api'))

        } else {
          //decode and ckeck access code expiration
          if (getAccessToken) {
            const decodedToken = decodeToken(getAccessToken);
            // console.log('decode access', decodedToken);
            if (decodedToken && decodedToken.exp) {
              const expirationTime = decodedToken.exp * 1000; // Convert to milliseconds
              const remainingTime = expirationTime - Date.now();
              //if expire time < 4 (14400000ms) hour get new tokens and save it in redux and cookies
              if (remainingTime < 14400000) {
                console.log('access expired');
                getRefreshTokenReq('/v1/token').then((res) => {
                  console.log('res access', res);
                  if (res.status === 200) {
                    SaveInCookies(res.data.tokens.access_token, res.data.tokens.refresh_token)
                    dispatch(setAccessTokens(res.data.tokens.access_token))
                    dispatch(setRefreshTokens(res.data.tokens.refresh_token))
                  }

                }).catch(error => console.log('server is not reachable'))
              }
            }
          }
        }
      }
    }
    if (getAccessToken) {
      if (palcode.length === 6) {
        getReq(`/v1/pal-code/?pal_code=${palcode}&pal_character_id=1`).then((res) => {
          if (res.message === "Network Error") {
            setLoading(false)
            toast.error('server is not reachable')
          } else {
            if (res.status === 200) {
              setLoading(false)
              dispatch(setPartnerAccountId(res.data.pal_code.account_id))
              dispatch(setPartnerCharacterId(res.data.pal_code.character_id))
              setIsModalOpen(true)
              // mqttHandler(getAccessToken)
            } else if (!res.status) {
              setLoading(false)
              setIsPalcodeValid(false)
              // toast.error('your palcode is invalid')
              setPalcode('')
            }
          }
        })
      } else {
        setIsModalOpen(true)
        setLoading(false)
      }

    } else {
      console.log('no accesstoken');
      const deviceId = uuidv4();
      const currentDate = new Date();
      const timestamp = currentDate.toISOString().replace(/[T:.Z-]/g, '');
      const sessionId = timestamp.split(' ').join('')



      const fetchData = async () => {
        setLoading(true)
        try {
          // const firebaseToken = await getFirebaseToken();
          const firebaseToken = 'web'
          // const firebaseToken = 'eEtHZAPvQr3LUms7FBVHJD:APA91bEX5ps2ibiylMWsS4xwjhlmwy29DFw8gnPZ69rw4oyYm66OXDcyWu_p9OwKTSVgQXp9xU4lGzGLnM-fPMfFiC4lKt0tLB8jBTbgIOjvFWSTLgYFxWye3TXh4Ch25he7mUfIpru8'
          // console.log('get firebase token', firebaseToken);
          // Function to handle reCAPTCHA verification
          window.grecaptcha.enterprise.ready(async () => {
            try {
              const reCapchaToken = await window.grecaptcha.enterprise.execute('6LfSTVApAAAAAFiH37i6c9OHisY0qv17YEHzgiJY', { action: 'LOGIN' });
              // Hide the badge
              const badge = document.querySelector('.grecaptcha-badge');

              if (badge) {
                badge.style.visibility = 'hidden';
              }
              // Now you can use the token as needed, e.g., send it to your API
              // console.log('reCAPTCHA Token:', reCapchaToken);
              // reCapchaToken = Token
              const res = await GetAccountId('/v1/accounts', {
                device_id: deviceId,
                session_id: sessionId,
                platform: serverFeild.Platform,
                app_version: serverFeild.appVersion,
                update_firebase: false,
                firebase_token: firebaseToken,
                captchaLogin: reCapchaToken
              });

              dispatch(setAccessTokens(res.tokens.access_token));
              dispatch(setRefreshTokens(res.tokens.refresh_token));
              dispatch(setAccountId(res.account_id));
              dispatch(setDeviceId(deviceId));
              dispatch(setSessionId(sessionId));
              dispatch(setFirebaseTokens(firebaseToken));
              SaveInCookies(res.tokens.access_token, res.tokens.refresh_token, res.account_id, deviceId, sessionId, firebaseToken);

              // mqttHandler(res.tokens.access_token);
              if (palcode.length === 6) {
                const palcodeRes = await getReq(`/v1/pal-code/?pal_code=${palcode}&pal_character_id=1`);

                if (palcodeRes.status === 200) {
                  setLoading(false);
                  dispatch(setPartnerAccountId(palcodeRes.data.pal_code.account_id));
                  dispatch(setPartnerCharacterId(palcodeRes.data.pal_code.character_id));
                  setIsModalOpen(true);
                } else {
                  setLoading(false);
                  setIsPalcodeValid(false)
                  // toast.error('Your palcode is invalid');
                  setPalcode('');
                }
              } else {
                setLoading(false)
                setIsModalOpen(true)
              }

            } catch (error) {
              console.error('Error executing reCAPTCHA:', error);
              setLoading(false);
              toast.error('server is not reachable');
              // Handle error
            }
          })


        } catch (error) {
          setLoading(false);
          // toast.error('server is not reachable now');
          console.log(error.message);
          toast.error(error.message)
        }
      };
      fetchData();
      // GetAccountId('/v1/accounts', {
      //   device_id: deviceId,
      //   session_id: sessionId,
      //   platform: serverFeild.Platform,
      //   app_version: serverFeild.appVersion,
      //   update_firebase: false,
      //   firebase_token: ""
      // }).then((res) => {
      //   // first , ve create new account and save it in cookies and redux 
      //   dispatch(setAccessTokens(res.tokens.access_token))
      //   dispatch(setRefreshTokens(res.tokens.refresh_token))
      //   dispatch(setAccountId(res.account_id))
      //   dispatch(setDeviceId(deviceId))
      //   dispatch(setSessionId(sessionId))
      //   SaveInCookies(res.tokens.access_token, res.tokens.refresh_token, res.account_id, deviceId, sessionId)
      //   // mqttHandler(res.tokens.access_token)
      //   //then , check palcode validity
      //   getReq(`/v1/pal-code/?pal_code=${palcode}&pal_character_id=1`).then((res) => {
      //     if (res.status === 200) {
      //       setLoading(false)
      //       dispatch(setPartnerAccountId(res.data.pal_code.account_id))
      //       dispatch(setPartnerCharacterId(res.data.pal_code.character_id))
      //       setIsModalOpen(true)
      //     } else {
      //       setLoading(false)
      //       toast.error('your palcode is invalid')
      //       setPalcode('')
      //     }
      //   }).catch((error) => {
      //     toast.error(error)
      //   })
      // }).catch((err) => {
      //   setLoading(false)
      //   toast.error('server is not reachable')
      // })
    }
  }
  // //set enter keyboard to click
  // useEffect(() => {

  //   const handleKeyPress = (e) => {
  //     // Check if the pressed key is a number (0-9)

  //     if (palcode?.length === 6 && e.key === 'Enter') {
  //       onClickDialHandler()
  //     }
  //   };
  //   window.addEventListener('keydown', handleKeyPress);
  //   return () => {
  //     window.removeEventListener('keydown', handleKeyPress);
  //   };
  // }, [palcode])

  return (
    <>
      {loading && <Modal isOpen={loading}>
        <Spinner />
      </Modal>}
      {!isPalcodeValid && <Modal isOpen={!isPalcodeValid}>
        <div className={styles.modalContainer}>
          <p><MdError size={30} color='red' /> palcode is invalid </p>
          <button className={styles.okBtn} onClick={() => setIsPalcodeValid(true)}>Ok</button>
        </div>

      </Modal>}
      {
        disable ? <button className={styles.dialBtnDisable} disabled>
          <MdCall size={45} color='white' />
        </button> : <button className={styles.dialBtn} onClick={onClickDialHandler}>
          <MdCall size={45} color='white' />
        </button>
      }
    </>
  )
}
export default DialBtn