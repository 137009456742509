
import styles from './downloadbtn.module.scss'
import { DeviceOS } from '../../../utils/Constants';
import { RiDownload2Fill } from 'react-icons/ri'
const DownloadBtn = ({ device, element }) => {
  const onClickHandler = (os) => {
    if (os === DeviceOS.IOS) {
      console.log('apple');
      window.location.href = 'https://apps.apple.com/us/app/palphone/id1637595045';
    } else if (os === DeviceOS.ANDROID) {
      window.location.href = 'https://play.google.com/store/apps/details?id=com.palphone.pro.app';
    }
  }
  return (
    <>
      <div className={styles.DownloadBtnContainer}>
        {element === 'header' && <button onClick={() => onClickHandler(device)}>
          <RiDownload2Fill size={25} color='white' />
        </button>}
      </div>
      {element === 'modal' && <button className={styles.modalBtn} onClick={() => onClickHandler(device)}>
        download <RiDownload2Fill size={25} color='white' />
      </button>}
    </>

  )
}
export default DownloadBtn