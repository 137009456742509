import CryptoJS from 'crypto-js';
import Cookies from "js-cookie";
import { Encryption_Keys, cookiesLabel } from "./Constants";

export const decodeToken = (token) => {
  try {
    // Decode the token without 'jsonwebtoken'
    const payload = atob(token.split('.')[1]);
    return JSON.parse(payload);
  } catch (error) {
    console.error('Failed to decode token:', error);
    return null;
  }
};


//encription function based on key
export const encryptText = (encriptingData, key) => {
  const encrypted = CryptoJS.AES.encrypt(encriptingData, key);
  return encrypted
};

//decription function based on key
export const decryptText = (encriptedData, key) => {
  const bytes = CryptoJS.AES.decrypt(encriptedData, key);
  const decrypted = bytes.toString(CryptoJS.enc.Utf8);
  return decrypted
};


//SAVE TO COOKIES 
export const SaveInCookies = (accesToken, refreshToken, accountId, deviceid, sessionid, firebaseToken) => {
  const encriptedAccessToken = encryptText(accesToken, Encryption_Keys.ACCESS_TOKEN_key)
  const encriptedAccountId = encryptText(String(accountId), Encryption_Keys.ACCOUNT_ID_KEY)

  Cookies.set(cookiesLabel.ACCESSTOKEN, encriptedAccessToken.toString(), { expires: 20 })
  Cookies.set(cookiesLabel.REFRESHTOKEN, refreshToken, { expires: 20 })
  if (accountId) {
    Cookies.set(cookiesLabel.ACCONTID, encriptedAccountId.toString(), { expires: 20 })
  }

  if (deviceid) {
    Cookies.set(cookiesLabel.DEVICEID, deviceid, { expires: 20 })
  }
  if (sessionid) {
    Cookies.set(cookiesLabel.SESSIONID, sessionid, { expires: 20 })
  }
  if (firebaseToken) {
    Cookies.set(cookiesLabel.FIREBASETOKEN, firebaseToken, { expires: 20 })
  }
}

export const getFromCookies = (cookiesData, encriptionKey) => {
  const cookieGotData = Cookies.get(cookiesData)
  if (cookieGotData) {
    const decriptedData = decryptText(cookieGotData, encriptionKey)
    return decriptedData
  }

}

