import { http, http2, media } from "./axios-service";


export const testApi = async (url, params, config = {}) => {
  const response = await http.get(url, params, config).catch((err) => err);
  if (response) return response;
};

//getting Account id and tokens
export const GetAccountId = async (url, data, config = {}) => {
  try {
    const response = await http.post(url, data, config);
    return response.data;
  } catch (error) {
    // You can handle errors here if needed
    console.error('Error in postApi:', error);
    throw error;
  }
};

export const getReq = async (url, params, config = {}) => {
  const response = await http.get(url, params, config).catch((err) => err);
  if (response) return response;
};
//Lets call
export const LetsCallReq = async (url, data, config = {}) => {
  try {
    const response = await http.post(url, data, config);
    return response.data
  } catch (error) {

    console.error('Error in postApi:', error);
    throw error;
  }
};
//push call
export const FirebasePushNotifReq = async (url, data, config = {}) => {
  try {
    const response = await http.post(url, data, config);
    return response
  } catch (error) {

    console.error('Error in postApi:', error);
    throw error;
  }
};

export const getRefreshTokenReq = async (url, params, config = {}) => {
  const response = await http2.get(url, params, config).catch((err) => err);
  if (response) return response;
};

//create room
export const getMediaIp = async (url, params, config = {}) => {
  const response = await media.get(url, params, config).catch((err) => err);
  if (response) return response;
};
